import React, { createContext, useState } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { Main } from "./pages";
import { Library } from "./pages/Library";
import { Book } from "./pages/Book";
import { ActiveModalProps } from "./shared/components/modals/types";

export const AuthContext = createContext<{
  authenticated: boolean;
  setAuthenticated?: (authenticated: boolean) => void;
}>({ authenticated: false });

export type ModalContextType = {
  activeModal?: ActiveModalProps;
  setActiveModal: (modalType?: ActiveModalProps) => void;
};

export const ModalContext = createContext<ModalContextType>({
  setActiveModal: () => undefined,
});

const queryClient = new QueryClient();

const router = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
  },
  {
    path: "/books",
    element: <Library />,
  },
  {
    path: "/book/:uuid",
    element: <Book />,
  },
]);

function App() {
  const [authenticated, setAuthenticated] = useState<boolean>(false);
  const [activeModal, setActiveModal] = useState<ActiveModalProps>();

  return (
    <div
      className='App d-flex'
      style={{
        height: "100vh",
        width: "100vw",
        fontFamily: "New Amsterdam",
        overflow: "hidden",
      }}
    >
      <AuthContext.Provider value={{ authenticated, setAuthenticated }}>
        <ModalContext.Provider value={{ activeModal, setActiveModal }}>
          <QueryClientProvider client={queryClient}>
            <RouterProvider router={router} />
          </QueryClientProvider>
        </ModalContext.Provider>
      </AuthContext.Provider>
    </div>
  );
}

export default App;
