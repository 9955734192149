import { QuaggaJSResultObject } from "@ericblade/quagga2";

export const getMedianOfCodeErrors = (result: QuaggaJSResultObject) => {
  const errors = result.codeResult.decodedCodes.flatMap((x) => x.error);
  const medianOfErrors = getMedian(errors);
  return medianOfErrors;
};

export const getMedian = (arr: (number | undefined)[]) => {
  const newArr = [...arr].filter((x) => typeof x !== "undefined");
  if (!newArr.length) {
    return;
  }
  newArr.sort((a, b) => a! - b!);
  const half = Math.floor(newArr.length / 2);
  if (newArr.length % 2 === 1) {
    return newArr[half];
  }
  return (newArr[half - 1]! + newArr[half]!) / 2;
};
