import Quagga, { QuaggaJSResultObject } from "@ericblade/quagga2";

export const drawQuaggaProcessing = (result: QuaggaJSResultObject) => {
  const drawingCtx = Quagga.canvas.ctx.overlay;
  const drawingCanvas = Quagga.canvas.dom.overlay;
  drawingCtx.font = "24px Arial";
  drawingCtx.fillStyle = "green";
  const width = drawingCanvas.getAttribute("width");
  const height = drawingCanvas.getAttribute("height");

  if (result) {
    if (result.boxes && width && height) {
      drawingCtx.clearRect(0, 0, parseInt(width), parseInt(height));
      result.boxes
        .filter((box) => box !== result.box)
        .forEach((box) => {
          Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, drawingCtx, {
            color: "purple",
            lineWidth: 2,
          });
        });
    }
    if (result.box) {
      Quagga.ImageDebug.drawPath(result.box, { x: 0, y: 1 }, drawingCtx, {
        color: "blue",
        lineWidth: 2,
      });
    }
    if (result.codeResult && result.codeResult.code) {
      drawingCtx.font = "24px Arial";
      drawingCtx.fillText(result.codeResult.code, 10, 20);
    }
  }
};
