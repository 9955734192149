import { useCallback, useContext, useEffect } from "react";
import { AuthContext } from "../../App";

type UseAuthHook = { authenticated: boolean };

export const useAuth = (): UseAuthHook => {
  const { authenticated, setAuthenticated } = useContext(AuthContext);

  const authenticationCheck = useCallback(
    async () =>
      await fetch("/api/authenticate", {
        method: "POST",
        body: JSON.stringify({ password: "authCheck" }),
        headers: {
          "Content-Type": "application/json",
        },
      }).then((res) => {
        setAuthenticated?.(res.ok);
      }),
    [setAuthenticated]
  );

  useEffect(() => {
    authenticationCheck();
  }, [authenticationCheck]);

  return { authenticated };
};
